@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Heebo";
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
.row.list-container {
  grid-column-gap: 0em;
  -webkit-column-gap: 0em;
          column-gap: 0em;
  grid-row-gap: 2em;
  row-gap: 2em;
}

.card-container {
  /* centers the cards in the row */
  float: none;
  margin: 0 auto;
  /* other styles */
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  padding-left: 12px;
  padding-right: 12px;
}
.card-container .card {
  background-color: #f8dde3;
  border: 0px solid grey;
  border-radius: 100px;
  font-family: "Heebo", sans-serif;
  padding: 0px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: 0 6px 8px 0 rgba(71, 16, 36, 0.06), 0 6px 20px 0 rgba(71, 16, 36, 0.06);
}
.card-container .card p {
  -webkit-margin-before: 0.1em;
          margin-block-start: 0.1em;
  -webkit-margin-after: 0.1em;
          margin-block-end: 0.1em;
}
.card-container .card h2 {
  text-align: center;
  font-weight: 500;
  font-size: 0.9rem;
  color: #590a39;
  padding: 4px 10px 0px 10px;
  margin-bottom: 4px;
}
.card-container .card .icon-container {
  min-height: 160px;
  display: table;
  margin-top: 6px;
}
.card-container .card .icon-container .icon-container-inner-wrapper {
  vertical-align: middle;
  display: table-cell;
  width: 100%;
}
.card-container .card .icon-container .item-description-container {
  font-size: 0.75rem;
  text-align: center;
  color: #7b2759;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-container .card .icon-container .item-description-container .item-description {
  display: inline-block;
  background-color: #eecad4;
  border-radius: 10px;
  max-width: 80%;
  line-height: 1;
  padding: 4px 12px;
}
.card-container .card .illustrated-icon {
  margin: 0 auto;
  display: block;
  margin: 0 auto;
}

.card-container:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.pickup-banner-bar {
  background-color: #e8f9f7;
  display: block;
  text-align: center;
  margin-bottom: 2px;
}

.pickup-text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #44c1b5;
  padding-left: 4px;
  padding-right: 5px;
}

.no-pickup-filler-bar {
  min-height: 34px;
  display: block;
  text-align: center;
}

.pickup-banner-col {
  padding: 0;
}

.needed-by-container {
  background-color: white;
  padding: 0px 0px 2px 0px;
  margin: 0;
  min-height: 140px;
}
.needed-by-container .needed-by-container-inner-wrapper {
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
}
.needed-by-container .charity-logo-container {
  padding-right: 2px;
  padding-left: 10px;
  max-width: 120px;
  height: auto;
}
.needed-by-container .charity-facts-container {
  padding-right: 0px;
  padding-left: 2px;
  display: flex;
}
.needed-by-container .charity-facts-container .charity-facts-container-inner-wrapper {
  display: table;
  width: 100%;
  min-height: 70px;
}
.needed-by-container .charity-facts-container .charity-facts-text-container {
  vertical-align: middle;
  display: table-cell;
  width: 100%;
  padding-left: 4px;
  padding-right: 8px;
}
.needed-by-container .charity-facts-container .needed-by-text {
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #aa809c;
  font-weight: 500;
}
.needed-by-container .charity-facts-container .charity-name {
  font-weight: 700;
  font-size: 0.85rem;
  line-height: 0.9rem;
}
.needed-by-container .charity-facts-container .charity-website {
  font-weight: 300;
  font-size: 0.75rem;
}

.donation-details-button-container {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  padding-bottom: 6px;
  margin: 0;
}

.donation-details-button {
  text-align: center;
  background: rgb(240, 147, 154);
  background: linear-gradient(90deg, rgb(240, 147, 154) 28%, rgb(235, 156, 192) 100%);
  border: 0px;
  color: white;
  font-size: 0.85rem;
  line-height: 0.85rem;
  font-weight: 700;
  padding: 10px 14px;
  border-radius: 6px 6px 6px 6px;
  margin: 2px 0px;
  cursor: pointer;
}

.details-page-link:hover {
  text-decoration: none;
}
.details-page-link:hover .charity-name {
  color: #bc2a57;
}
/* card-list component only cares about displaying cards in a configuration*/
.card-list {
  max-width: 1600px;
  padding: 20px;
}
.search {
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 10px;
    width: 380px;
    line-height: 30px;
    margin-bottom: 30px;
    background-color: #FFF3F6;
    color: #590a39;
}
.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
  background-color: #f7f8f8;
  font-family: "Heebo", sans-serif;
}

body {
  background-color: #fff3f6;
}

.SearchToDonate .search {
  display: block;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0 auto;
  font-family: "Heebo", sans-serif;
  border-bottom: 2px solid #c17091;
  caret-color: #c17091;
  color: #590a39;
}

@media (max-width: 767px) {
  .SearchToDonate .search {
    width: 320px;
    font-size: 1.3rem;
    font-weight: 400;
  }
}
@media (min-width: 768px) {
  .SearchToDonate .search {
    width: 400px;
    font-size: 1.7rem;
    font-weight: 200;
  }
}
input {
  border-radius: 0cm;
}

input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type=search]::-moz-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type=search]:-ms-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type=search]::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7b2759;
  background: #7b2759;
  background-color: #7b2759;
  pointer-events: all;
  cursor: pointer;
}

input[type=search]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7b2759;
  background: #7b2759;
  pointer-events: all;
  cursor: pointer;
}
.list-container {
  grid-column-gap: 0em;
  -webkit-column-gap: 0em;
          column-gap: 0em;
  grid-row-gap: 2em;
  row-gap: 2em;
}

.hidden {
  display: none;
}

.charity-card-container {
  /* centers the cards in the row */
  float: none;
  margin: 0 auto;
  /* other styles */
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  font-family: "Heebo", sans-serif;
}
@media (max-width: 767px) {
  .charity-card-container .charity-card {
    margin-left: 10px;
    margin-right: 10px;
  }
  .charity-card-container .charity-card .charity-text-area {
    padding-left: 20px;
    padding-bottom: 12px;
    text-align: center;
  }
  .charity-card-container .charity-card .charity-text-area h2 {
    text-align: center;
  }
  .charity-card-container .charity-card img.charity-logo {
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .charity-card-container .charity-card .org-type-container {
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .charity-card-container .charity-card .mission-statement {
    margin-left: 5%;
    margin-right: 5%;
  }
  .charity-card-container .charity-card .charity-text-area h2 {
    padding: 8px 2px 0px 0px;
  }
}
@media (min-width: 768px) {
  .charity-card-container img.charity-logo {
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .charity-card-container .mission-statement {
    width: 90%;
  }
}
@media (min-width: 768px) {
  .charity-card-container .charity-text-area h2 {
    padding: 18px 2px 0px 0px;
  }
}
.charity-card-container .charity-card {
  background-color: white;
  border: 0px solid grey;
  border-radius: 10px;
  font-family: "Heebo", sans-serif;
  padding: 0px;
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  min-height: 20px;
  box-shadow: 0 4px 8px 0 rgba(71, 16, 36, 0.06), 0 6px 20px 0 rgba(71, 16, 36, 0.06);
}
.charity-card-container .charity-card p {
  -webkit-margin-before: 0.1em;
          margin-block-start: 0.1em;
  -webkit-margin-after: 0.1em;
          margin-block-end: 0.1em;
}
.charity-card-container .charity-card .charity-website {
  padding-top: 6px;
  padding-bottom: 4px;
  font-size: 0.9rem;
}
.charity-card-container .charity-card .charity-website a {
  color: #875378;
}
.charity-card-container .charity-card .mission-statement {
  padding-bottom: 4px;
  font-size: 0.9rem;
  line-height: 20px;
  font-family: "Heebo";
  color: #350b16;
}
.charity-card-container .charity-card h2 {
  text-align: left;
  font-weight: 700;
  font-size: 1.3rem;
  color: #590a39;
}
.charity-card-container .charity-card h3 {
  text-align: left;
  font-weight: 500;
  font-size: 1.05rem;
  color: #590a39;
  padding: 12px 2px 0px 0px;
}
.charity-card-container .charity-card h4.wishlist-title {
  color: #986c4b;
  font-size: 0.95rem;
}
.charity-card-container .charity-card .ui-icon {
  margin-right: 6px;
}
.charity-card-container .charity-card .wishlist-icon {
  margin-right: 8px;
}
.charity-card-container .charity-card .charity-summary-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.charity-card-container .charity-card .charity-category-badge {
  background-color: white;
  padding: 4px 10px;
  font-size: 86%;
  color: #aa809c;
  border: 1px solid #aa809c;
  margin-bottom: 10px;
  text-transform: lowercase;
}
.charity-card-container .charity-card .org-type-badge {
  padding: 5px 10px;
  font-size: 82%;
  z-index: 500;
  position: relative;
}
.charity-card-container .charity-card .org-type-badge-and-icon {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.charity-card-container .charity-card .org-type-badge.org-type-government {
  background-color: #87522e;
}
.charity-card-container .charity-card .org-type-badge.org-type-business {
  background-color: #0c7265;
}
.charity-card-container .charity-card .org-type-badge.org-type-nonprofit {
  background-color: #911f3d;
}
.charity-card-container .charity-card .org-type-badge.org-type-community-effort {
  background-color: #738e2f;
}
.charity-card-container .charity-card .org-type-icon {
  margin-right: -8px;
  z-index: 5000 !important;
  position: relative;
}
.charity-card-container .charity-card .wishlist-button {
  background-color: #fcecde;
  color: #986c4b;
  border-color: #efd6c5;
  border-radius: 30px;
  padding-top: 15px;
  line-height: 36px;
  padding-left: 12px;
  padding-right: 20px;
  margin: 6px;
  text-align: center;
  display: inline-flex;
}
.charity-card-container .charity-card .wishlist-button:hover {
  background-color: #fdb73e;
  text-decoration: none;
}
.charity-card-container .charity-card .wishlist-link {
  text-decoration: none;
}
.charity-card-container .charity-card .wishlist-link:hover {
  text-decoration: none;
}
.charity-card-container .charity-card .items-accordion-container {
  padding: 0px;
  color: #590a39;
}
.charity-card-container .charity-card .items-accordion-container .accordion-trigger-link {
  text-decoration: none;
  color: #590a39;
}
.charity-card-container .charity-card .items-accordion-container .accordion-trigger-link:hover {
  text-decoration: none;
  color: #590a39;
}
.charity-card-container .charity-card .items-accordion-container .cols-for-tiles {
  padding: 0px;
  margin: 0px;
}
.charity-card-container .charity-card .items-accordion-container .accordion {
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.charity-card-container .charity-card .items-accordion-container .donated-thing-icon {
  padding-bottom: 3px;
  top: -3px;
  margin-right: 6px;
}
.charity-card-container .charity-card .items-accordion-container .want-count-text {
  margin-right: 14px;
}
.charity-card-container .charity-card .items-accordion-container .card {
  background-color: #f8dde3;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.charity-card-container .charity-card .items-accordion-container .card .wishlist-heading {
  margin-top: 8px;
}
.charity-card-container .charity-card .items-accordion-container .card .external-website-link-container {
  margin-bottom: 30px;
}
.charity-card-container .charity-card .items-accordion-container .card .thumbnail-card {
  margin: 10px;
}
.charity-card-container .charity-card .items-accordion-container .card .item-name {
  line-height: 1rem;
  color: #590a39;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  text-transform: lowercase;
  padding-top: 4px;
}
.charity-card-container .charity-card .items-accordion-container .card .item-description-container {
  font-size: 0.75rem;
  text-align: center;
  color: #7b2759;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0.7rem;
  margin: 0;
  padding: 0;
}
.charity-card-container .charity-card .items-accordion-container .card .item-description-container .item-description {
  display: inline-block;
  background-color: #eecad4;
  border-radius: 10px;
  max-width: 100%;
  line-height: 1;
  padding: 5px 8px;
}
.charity-card-container .charity-card .illustrated-icon {
  margin: 0 auto;
  display: block;
  padding: 2px;
}

.charity-card-container:hover {
  -webkit-transform: scale(1.03);
          transform: scale(1.03);
  z-index: 2000;
}

.donation-details-button.to-charity-site {
  background: rgb(240, 147, 154);
  background: linear-gradient(90deg, rgb(240, 147, 154) 28%, rgb(235, 156, 192) 100%);
  color: white;
  border-color: #efd6c5;
  border-radius: 32px;
  padding-top: 20px;
  line-height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 6px;
  text-align: center;
  display: inline-flex;
}
.donation-details-button.to-charity-site:hover {
  background: rgb(235, 156, 192);
  text-decoration: none;
  cursor: pointer;
}
.donation-details-button.to-charity-site .details-title {
  font-size: 0.95rem;
}

.details-link:hover {
  text-decoration: none;
  cursor: pointer;
}

.maps-link {
  font-size: 0.85rem;
  color: #ef6ca0;
  background-color: #f8f9fa;
  border: none;
  border-top: 3px solid #f8f9fa;
  padding: 2px 8px 3px 8px;
  border-radius: 0.2rem;
  margin: 0px 8px 10px 0px;
}
.maps-link:hover {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border-top: 3px solid #fff3f6;
  cursor: pointer;
}

.arrange-pickup-container {
  text-align: center;
  line-height: 1.2rem;
}
.arrange-pickup-container .arrange-pickup-title {
  color: #590a39;
  font-size: 0.95rem;
  font-weight: 700;
  padding-top: 8px;
}
.arrange-pickup-container .arrange-pickup-description {
  color: #8b3f6b;
  font-size: 0.9rem;
}
.arrange-pickup-container .arrange-pickup-cta {
  color: #8b3f6b;
  font-size: 0.9rem;
  font-weight: 700;
}
.arrange-pickup-container .arrange-pickup-zip-codes {
  color: #aa809c;
  font-size: 0.8rem;
  padding-top: 6px;
}

.popover {
  border: 1px solid #ee9ab1;
  z-index: 2500;
}

.top-popover .popover-body {
  color: #d15688;
  padding: 0.3rem 0.75rem;
}

.popover .arrow {
  border-width: 1px 1px 0;
  border-top-color: #ee9ab1 !important;
}

.bs-popover-auto[x-placement^=top] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: #ee9ab1 !important;
}

.small-button {
  color: #ef6ca0;
  font-size: 0.85rem;
  background-color: none;
  border: none;
  margin-top: 4px;
  margin-bottom: 8px;
}
.small-button:hover {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.small-button:active {
  background: #fff3f6 !important;
  color: #ef6ca0 !important;
  text-decoration: none;
  border: none;
}
.small-button:focus {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border: none;
}
.small-button:visited {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border: none;
}
.thumbnail-card {
  margin: 0;
  padding: 0;
}
.thumbnail-card {
  margin: 0;
  padding: 0;
}

.whole-wishlist-link:hover {
  -webkit-text-decoration: underline dotted #ef6ba0;
          text-decoration: underline dotted #ef6ba0;
}

.wishlist-title-container {
  max-width: 128px;
}

.wishlist-item-name {
  color: #aa809c;
}
/* card-list component only cares about displaying cards in a configuration*/
.charity-card-list {
  max-width: 1600px;
  padding: 20px;
}
.charities {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
}

body {
  background-color: #fff3f6;
}

h1.page-title {
  text-align: center;
  font-weight: 200;
  font-size: 1.7rem;
  color: #9b5977;
  font-family: "Heebo";
  padding: 32px 0 0 0;
}

.SearchForCharity .search {
  display: block;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0 auto;
  font-family: "Heebo", sans-serif;
  border-bottom: 2px solid #c17091;
  caret-color: #c17091;
  color: #590a39;
}

@media (max-width: 767px) {
  .SearchForCharity .search {
    width: 320px;
    font-weight: 400;
    font-size: 1.3rem;
  }
}
@media (min-width: 768px) {
  .SearchForCharity .search {
    width: 380px;
    font-weight: 200;
    font-size: 1.7rem;
  }
}
input {
  border-radius: 0cm;
}

input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type=search]::-moz-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type=search]:-ms-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type=search].charity-search-input::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7b2759;
  background: #7b2759;
  background-color: #7b2759;
  pointer-events: all;
  cursor: pointer;
}

input[type=search]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7b2759;
  background: #7b2759;
  pointer-events: all;
  cursor: pointer;
}

.add-charity-message-container {
  margin: 0 auto;
  text-align: center;
  color: #875378;
}
.list-container {
  grid-column-gap: 0em;
  -webkit-column-gap: 0em;
          column-gap: 0em;
  grid-row-gap: 2em;
  row-gap: 2em;
}

.hidden {
  display: none;
}

h1 {
  text-align: center;
  font-weight: 200;
  font-size: 1.7rem;
  color: #9b5977;
  font-family: "Heebo";
  padding: 18px 0 0 0;
}

.row.list-container {
  grid-row-gap: 1em;
  grid-row-gap: 1em;
  row-gap: 1em;
}

.charity-card-container {
  /* centers the cards in the row */
  float: none;
  margin: 0 auto;
  /* other styles */
  -webkit-transform: none;
          transform: none;
  transition: none;
  font-family: "Heebo", sans-serif;
}
@media (max-width: 767px) {
  .charity-card-container .charity-card {
    margin-left: 10px;
    margin-right: 10px;
    cursor: default;
  }
  .charity-card-container .charity-card .charity-text-area {
    padding-left: 20px;
    padding-bottom: 12px;
    text-align: center;
  }
  .charity-card-container .charity-card .charity-text-area h2 {
    text-align: center;
  }
  .charity-card-container .charity-card img.charity-logo {
    margin: 0 auto;
    text-align: center;
    display: block;
    width: 140px;
  }
  .charity-card-container .charity-card .org-type-container {
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .charity-card-container .charity-card .mission-statement {
    margin-left: 5%;
    margin-right: 5%;
  }
  .charity-card-container .charity-card .charity-text-area h2 {
    padding: 6px 2px 0px 0px;
  }
}
@media (min-width: 768px) {
  .charity-card-container .charity-text-area {
    padding-left: 2px;
  }
  .charity-card-container img.charity-logo {
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  .charity-card-container .mission-statement {
    width: 90%;
  }
  .charity-card-container .charity-text-area h2 {
    padding: 18px 2px 0px 0px;
  }
}
.charity-card-container .charity-card {
  background-color: white;
  border: 0px solid grey;
  border-radius: 10px;
  font-family: "Heebo", sans-serif;
  padding: 0px;
  cursor: default;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  min-height: 20px;
  box-shadow: 0 4px 8px 0 rgba(71, 16, 36, 0.06), 0 6px 20px 0 rgba(71, 16, 36, 0.06);
}
.charity-card-container .charity-card p {
  -webkit-margin-before: 0.1em;
          margin-block-start: 0.1em;
  -webkit-margin-after: 0.1em;
          margin-block-end: 0.1em;
}
.charity-card-container .charity-card .charity-website {
  padding-top: 0px;
  padding-bottom: 4px;
  font-size: 0.9rem;
}
.charity-card-container .charity-card .charity-website a {
  color: #875378;
}
.charity-card-container .charity-card .mission-statement {
  padding-bottom: 4px;
  font-size: 0.9rem;
  line-height: 20px;
  font-family: "Heebo";
  color: #350b16;
}
.charity-card-container .charity-card h1.page-title {
  text-align: left;
  font-weight: 200;
  font-size: 1.2rem;
  color: #9b5977;
  font-family: "Heebo";
}
.charity-card-container .charity-card h2 {
  text-align: left;
  font-weight: 500;
  font-size: 1.2rem;
  color: #590a39;
}
.charity-card-container .charity-card h3 {
  text-align: left;
  font-weight: 500;
  font-size: 1.15rem;
  color: #590a39;
  padding: 6px 2px 0px 0px;
}
.charity-card-container .charity-card h4 {
  text-align: left;
  font-weight: 500;
  font-size: 1.2rem;
  color: #590a39;
  padding: 6px 2px 6px 0px;
}
.charity-card-container .charity-card h4.wishlist-title {
  color: #986c4b;
  font-size: 0.95rem;
}
.charity-card-container .charity-card .cols-for-wishlist-tiles {
  padding-left: 8px;
  padding-right: 8px;
}
.charity-card-container .charity-card .wishlist-item-name {
  line-height: 1rem;
  color: #590a39;
  font-weight: 400;
  font-size: 0.8rem;
  text-align: left;
  padding-bottom: 8px;
}
.charity-card-container .charity-card .wishlist-items-container {
  background: white;
  border-radius: 20px;
  border: 1px solid #efd6c5;
}
.charity-card-container .charity-card .ui-icon {
  margin-right: 6px;
}
.charity-card-container .charity-card .wishlist-icon {
  margin-right: 8px;
}
.charity-card-container .charity-card .charity-summary-container {
  margin-top: 10px;
  margin-bottom: 10px;
}
.charity-card-container .charity-card .charity-category-badge {
  background-color: white;
  padding: 4px 10px;
  font-size: 86%;
  color: #aa809c;
  border: 1px solid #aa809c;
  margin-bottom: 10px;
  text-transform: lowercase;
}
.charity-card-container .charity-card .org-type-badge {
  padding: 5px 10px;
  font-size: 82%;
  z-index: 500;
  position: relative;
}
.charity-card-container .charity-card .org-type-badge-and-icon {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.charity-card-container .charity-card .org-type-badge.org-type-government {
  background-color: #87522e;
}
.charity-card-container .charity-card .org-type-badge.org-type-business {
  background-color: #0c7265;
}
.charity-card-container .charity-card .org-type-badge.org-type-nonprofit {
  background-color: #911f3d;
}
.charity-card-container .charity-card .org-type-badge.org-type-community-effort {
  background-color: #738e2f;
}
.charity-card-container .charity-card .org-type-icon {
  margin-right: -8px;
  z-index: 5000 !important;
  position: relative;
}
.charity-card-container .charity-card .wishlist-button {
  background-color: #fcecde;
  color: #986c4b;
  border-color: #efd6c5;
  border-radius: 30px;
  padding-top: 15px;
  line-height: 36px;
  padding-left: 12px;
  padding-right: 20px;
  margin: 12px;
  text-align: center;
  display: inline-flex;
}
.charity-card-container .charity-card .wishlist-button:hover {
  background-color: #fdb73e;
  text-decoration: none;
}
.charity-card-container .charity-card .wishlist-link {
  text-decoration: none;
}
.charity-card-container .charity-card .wishlist-link:hover {
  text-decoration: none;
}
.charity-card-container .charity-card .items-accordion-container {
  padding: 0px;
  color: #590a39;
}
.charity-card-container .charity-card .items-accordion-container .accordion-trigger-link {
  text-decoration: none;
  color: #590a39;
}
.charity-card-container .charity-card .items-accordion-container .accordion-trigger-link:hover {
  text-decoration: none;
  color: #590a39;
}
.charity-card-container .charity-card .items-accordion-container .cols-for-tiles {
  padding: 0px;
  margin: 0px;
}
.charity-card-container .charity-card .items-accordion-container .accordion {
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.charity-card-container .charity-card .items-accordion-container .donated-thing-icon {
  padding-bottom: 3px;
  top: -3px;
  margin-right: 6px;
}
.charity-card-container .charity-card .items-accordion-container .want-count-text {
  margin-right: 16px;
}
.charity-card-container .charity-card .items-accordion-container .card {
  background-color: #f8dde3;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.charity-card-container .charity-card .items-accordion-container .card .wishlist-heading {
  margin-top: 8px;
}
.charity-card-container .charity-card .items-accordion-container .card .external-website-link-container {
  margin-bottom: 30px;
}
.charity-card-container .charity-card .items-accordion-container .card .thumbnail-card {
  margin: 10px;
}
.charity-card-container .charity-card .items-accordion-container .card .item-name {
  line-height: 1rem;
  color: #590a39;
  font-weight: 500;
  font-size: 0.9rem;
  text-align: center;
  text-transform: lowercase;
  padding-top: 4px;
}
.charity-card-container .charity-card .items-accordion-container .card .item-description-container {
  font-size: 0.75rem;
  text-align: center;
  color: #7b2759;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0.7rem;
  margin: 0;
  padding: 0;
}
.charity-card-container .charity-card .items-accordion-container .card .item-description-container .item-description {
  display: inline-block;
  background-color: #eecad4;
  border-radius: 10px;
  max-width: 100%;
  line-height: 1;
  padding: 5px 8px;
}
.charity-card-container .charity-card .illustrated-icon {
  margin: 0 auto;
  display: block;
  padding: 2px;
}

.charity-card-container:hover {
  -webkit-transform: none;
          transform: none;
  cursor: default;
}

.arrange-pickup-container {
  text-align: center;
  line-height: 1.2rem;
}
.arrange-pickup-container .arrange-pickup-title {
  color: #590a39;
  font-size: 0.95rem;
  font-weight: 700;
  padding-top: 8px;
}
.arrange-pickup-container .arrange-pickup-description {
  color: #8b3f6b;
  font-size: 0.9rem;
}
.arrange-pickup-container .arrange-pickup-cta {
  color: #8b3f6b;
  font-size: 0.9rem;
  font-weight: 700;
}
.arrange-pickup-container .arrange-pickup-zip-codes {
  color: #aa809c;
  font-size: 0.8rem;
  padding-top: 6px;
}

.donation-detail-label {
  color: #aa809c;
  font-size: 0.85rem;
  padding-top: 12px;
}

.copy-text-button,
.small-button {
  color: #ef6ca0;
  font-size: 0.85rem;
  background-color: none;
  border: none;
  margin-top: 4px;
  margin-bottom: 8px;
}
.copy-text-button:hover,
.small-button:hover {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border: none;
  cursor: pointer;
}
.copy-text-button:active,
.small-button:active {
  background: #fff3f6 !important;
  color: #ef6ca0 !important;
  text-decoration: none;
  border: none;
}
.copy-text-button:focus,
.small-button:focus {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border: none;
}
.copy-text-button:visited,
.small-button:visited {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border: none;
}

.donation-email {
  color: #aa437e;
}

.donation-instructions {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: "Heebo";
  color: #8b3f6b;
  text-align: center;
  padding: 12px;
}

.donation-address,
.donation-coordination-name,
.donation-email {
  font-size: 0.9rem;
  font-weight: 700;
}

.donation-details-button.charity-type {
  background: rgb(240, 147, 154);
  background: linear-gradient(90deg, rgb(240, 147, 154) 28%, rgb(235, 156, 192) 100%);
  color: white;
  border-color: #efd6c5;
  border-radius: 32px;
  padding-top: 20px;
  line-height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 6px;
  text-align: center;
  display: inline-flex;
}
.donation-details-button.charity-type:hover {
  background: rgb(235, 156, 192);
  text-decoration: none;
  cursor: pointer;
}
.donation-details-button.charity-type .details-title {
  font-size: 0.95rem;
}

.popover {
  border: 1px solid #ee9ab1;
  z-index: 2500;
}

.maps-link {
  font-size: 0.85rem;
  color: #ef6ca0;
  background-color: #f8f9fa;
  border: none;
  border-top: 3px solid #f8f9fa;
  padding: 2px 8px 3px 8px;
  border-radius: 0.2rem;
  margin: 0px 8px 10px 0px;
}
.maps-link:hover {
  background: #fff3f6;
  color: #ef6ca0;
  text-decoration: none;
  border-top: 3px solid #fff3f6;
  cursor: pointer;
}

.top-popover .popover-body {
  color: #d15688;
  padding: 0.3rem 0.75rem;
}

.popover .arrow {
  border-width: 1px 1px 0;
  border-top-color: #ee9ab1 !important;
}

.bs-popover-auto[x-placement^=top] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: #ee9ab1 !important;
}

.details-link:hover {
  text-decoration: none;
}

.wishlist-guidance {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: "Heebo";
  color: #875378;
  text-align: center;
  padding: 2px;
}
.charities {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
}

body {
  background-color: #FFF3F6;
}

.SearchForCharity .search {
  display: block;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0 auto;
  font-family: "Heebo", sans-serif;
  border-bottom: 2px solid #C17091;
  caret-color: #C17091;
  color: #590a39;
}

@media (max-width: 767px) {
  .SearchForCharity .search {
    width: 320px;
    font-weight: 400;
    font-size: 1.3rem;
  }
}
@media (min-width: 768px) {
  .SearchForCharity .search {
    width: 380px;
    font-weight: 200;
    font-size: 1.7rem;
  }
}
input {
  border-radius: 0cm;
}

input[type=search] {
  -webkit-appearance: none;
}

input[type=search]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #DBACBD;
  width: 100%;
}

input[type=search]::-moz-placeholder { /* Chrome/Opera/Safari */
  color: #DBACBD;
  width: 100%;
}

input[type=search]:-ms-input-placeholder { /* Chrome/Opera/Safari */
  color: #DBACBD;
  width: 100%;
}

input[type=search].charity-search-input::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7B2759;
  background: #7B2759;
  background-color: #7B2759;
  pointer-events: all;
  cursor: pointer;
}

input[type=search]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7B2759;
  background: #7B2759;
  pointer-events: all;
  cursor: pointer;
}
.page-container {
  margin-top: 40px;
}

.whole-list-area {
  margin-left: 40px;
  margin-right: 40px;
}

.draggable-overall-container {
  position: relative;
  width: 100%;
  height: 240px;
  display: inline-block;
}

@media (max-width: 767px) {
  .draggable-overall-container {
    width: 420px;
  }
}
@media (min-width: 768px) {
  .draggable-overall-container {
    width: 100%;
  }
}
.admin-wishlist-page .draggable-item-area {
  position: absolute;
  width: 100%;
  height: 80px;
  -webkit-transform-origin: 50% 50% 0px;
          transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: #590a39;
  line-height: 80px;
  padding-left: 32px;
  font-size: 0.9rem;
  font-weight: 500;
  background: white;
  text-transform: lowercase;
  touch-action: none;
}

.ranking-col {
  max-width: 15%;
}

.ranking-container {
  padding-top: 28px;
  display: inline-block;
  width: 20px;
}

.rank-label {
  height: 100px;
  text-align: right;
}

.about-me-circle-imgs {
  width: 100%;
  max-width: 160px;
  vertical-align: middle;
}
.about-text-container {
  color: #9b5977;
  font-size: 1em;
  margin-top: 30px;
}

h1.page-title {
  text-align: center;
  font-weight: 200;
  font-size: 1.7rem;
  color: #9b5977;
  font-family: "Heebo";
  padding: 32px 0 20px 0;
}

h2 {
  font-weight: 600;
  font-size: 1.1rem;
}

.about-me-circle-imgs {
  width: 100%;
  max-width: 160px;
  vertical-align: middle;
}

.link-to-outside-pages {
  font-weight: 600;
  text-decoration: none;
  color: #9b5977;
}
.link-to-outside-pages :hover {
  text-decoration: none;
  color: #9b5977;
}
.header {
  padding: 0;
  margin: 0;
  font-family: "Heebo", sans-serif;
}
.header .sky-background {
  height: 170px;
  background-color: #e8f7f9;
}
.header .heartening-logo-stamp {
  position: absolute;
  top: 0;
  left: 30px;
  background-color: #f8dde3;
  border-radius: 0px 0px 50px 50px;
  padding-top: 10px;
  color: #9b5977;
  font-size: 0.85rem;
  text-align: center;
}
.header .heartening-logo-stamp .heartening-logo-container {
  margin-right: 6px;
}
.header .heartening-logo-stamp .heartening-logo {
  margin: 0 auto;
  display: block;
  overflow: hidden;
}
.header .heartening-logo-stamp .logo-url-text {
  padding-top: 8px;
  color: #f15a2d;
  font-weight: 700;
}
.header .heartening-logo-stamp svg {
  margin-right: 6px;
}
@media (min-width: 768px) {
  .header .heartening-logo-stamp {
    height: 128px;
    width: 120px;
  }
}
@media (max-width: 767px) {
  .header .heartening-logo-stamp {
    visibility: hidden;
  }
}
.header .book-a-pick-up {
  left: 150px;
  width: 134px;
  height: 42px;
  top: 42px;
  background-color: #f76b39;
  position: absolute;
  border-bottom-right-radius: 26px;
  color: #f8dde3 !important;
  font-size: 0.85em;
  line-height: 3em;
  padding-right: 26px;
  text-align: right;
  font-weight: 700 !important;
}
.header .book-a-pick-up .book-a-pick-up-link {
  color: #f8dde3 !important;
  text-decoration: none !important;
}
.header .book-a-pick-up:hover {
  background-color: #d8542b;
  text-decoration: none !important;
  cursor: pointer;
}
.header .book-a-drop-off {
  left: 150px;
  width: 190px;
  height: 42px;
  top: 0px;
  background-color: #eda9c8;
  position: absolute;
  border-bottom-right-radius: 26px;
  color: #915071 !important;
  font-size: 0.85em;
  line-height: 3em;
  padding-right: 26px;
  text-align: right;
  font-weight: 700 !important;
}
.header .book-a-drop-off .book-a-drop-off-link {
  color: #915071 !important;
  text-decoration: none !important;
}
.header .book-a-drop-off:hover {
  background-color: #f499bf;
  text-decoration: none !important;
  cursor: pointer;
}
.header .instagram-blob {
  width: 48px;
  height: 56px;
  background-color: #e0cfe6;
  position: absolute;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  top: 0px;
  right: 28px;
  font-size: 0.8em;
  line-height: 2.7em;
  padding-top: 12px;
  padding-left: 10px;
}
.header .addacause-blob {
  width: 48px;
  height: 56px;
  background-color: #b6e1e3;
  position: absolute;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  top: 0px;
  right: 88px;
  font-size: 0.8em;
  line-height: 2.7em;
  padding-top: 10px;
  padding-left: 9px;
}
.header .about-me-blob {
  width: 60px;
  height: 56px;
  background-color: #f8dde3;
  color: #9b5977;
  font-weight: 600;
  position: absolute;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  top: 0px;
  right: 148px;
  font-size: 0.8em;
  line-height: 2.7em;
  padding-top: 10px;
  padding-left: 12px;
}
.header .about-me-link {
  text-decoration: none;
  color: #9b5977;
}
@media (max-width: 767px) {
  .header .book-a-pick-up,
  .header .book-a-drop-off {
    left: 0px;
  }
  .header .book-a-pick-up {
    width: 130px;
  }
  .header .book-a-drop-off {
    width: 190px;
  }
  .header .instagram-blob {
    right: 12px;
  }
  .header .addacause-blob {
    right: 72px;
  }
  .header .about-me-blob {
    right: 132px;
  }
  .header .thick-ribbon {
    height: 4.5rem;
  }
}
@media (min-width: 768px) {
  .header .thick-ribbon {
    height: 5.5rem;
  }
}
.header .thick-ribbon {
  background-color: #f8dde3;
}
.header .thick-ribbon .header-center-anchor {
  width: 1px; /* Or zero, or something very small */
  margin: auto;
  overflow: visible;
}
@media (max-width: 767px) {
  .header .thick-ribbon .nav-container {
    margin-left: 200px;
    margin-top: -90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 905px) {
  .header .thick-ribbon .nav-container {
    margin-left: 146px;
    margin-top: -90px;
  }
}
@media (min-width: 906px) {
  .header .thick-ribbon .nav-container {
    margin-left: 200px;
    margin-top: -90px;
  }
}
.header .thick-ribbon .city-statement {
  font-size: 0.9rem;
  line-height: 5.5rem;
  color: #9b5977;
  width: 270px;
}
.header .thick-ribbon .city-statement .selected-city {
  font-weight: 800;
  margin-left: 6px;
}
@media (max-width: 767px) {
  .header .thick-ribbon .city-statement {
    margin-left: -400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 905px) {
  .header .thick-ribbon .city-statement {
    margin-left: -340px;
  }
}
@media (min-width: 906px) {
  .header .thick-ribbon .city-statement {
    margin-left: -430px;
  }
}
.header .nav-container {
  font-size: 0.9rem;
  line-height: 1rem;
  color: #9b5977;
  width: 225px;
  margin: 0 auto;
}
.header .nav-container .pages-nav-pills {
  background: #fbebee;
  padding: 6px;
  border-radius: 8px;
}
.header .nav-container .pages-nav-pills .nav-item .page-nav-icon {
  margin-right: 6px;
}
.header .nav-container .pages-nav-pills .nav-item .nav-link {
  padding: 0.7rem 1rem;
}
.header .nav-container .pages-nav-pills .nav-item a {
  color: #9b5977;
  font-weight: 700;
}
.header .nav-container .pages-nav-pills .nav-link.active {
  background-color: white;
}
.header .mobile-city-statement {
  text-align: center;
  margin: 0 auto;
  font-size: 1rem;
  line-height: 4.8rem;
  color: #9b5977;
  width: 100%;
}
.header .mobile-city-statement .selected-city {
  font-weight: 800;
  margin-left: 6px;
}
.header .city-illustration {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .header .city-illustration {
    width: 336px;
    height: 224px;
    top: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 905px) {
  .header .city-illustration {
    width: 356px;
    height: 224px;
    top: 44px;
  }
}
@media (min-width: 906px) {
  .header .city-illustration {
    width: 446px;
    height: 224px;
    top: 44px;
  }
}
.header .logo-container {
  width: 100px;
  height: 100px;
  padding: 25px;
}
.header .options {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header .options .option {
  padding: 8px 15px;
  cursor: pointer;
}
