.charities {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 80px;
  }

body {
    background-color: #FFF3F6;
  }

  .SearchForCharity .search{
    display: block;
    padding-top: 50px;
    padding-bottom: 10px;
    margin: 0 auto;
    font-family: 'Heebo', sans-serif;
    border-bottom: 2px solid #C17091;
    caret-color: #C17091;
    color: #590a39;
  }

  @media (max-width: 767px) {
    .SearchForCharity .search{
      width: 320px;
      font-weight: 400;
      font-size: 1.3rem;
    }
  }

  @media (min-width: 768px) {
    .SearchForCharity .search{
      width: 380px;
      font-weight: 200;
      font-size: 1.7rem;
    }
  }
  input {
    border-radius: 0cm;
  }

  input[type=search] {  
    -webkit-appearance: none;          
  }

  input[type=search]::-webkit-input-placeholder { /* Chrome/Opera/Safari */ 
    color: #DBACBD; 
    width: 100%;            
  }

  input[type=search]::-moz-placeholder { /* Chrome/Opera/Safari */ 
    color: #DBACBD; 
    width: 100%;               
  }
  
  input[type=search]:-ms-input-placeholder{ /* Chrome/Opera/Safari */ 
    color: #DBACBD;   
    width: 100%;              
  }


  input[type="search"].charity-search-input::-webkit-search-cancel-button {
    opacity: .3;
    color:#7B2759;
    background:#7B2759;
    background-color: #7B2759;;
    pointer-events: all;
    cursor: pointer; 
  }

  input[type="search"]:focus::-webkit-search-cancel-button {
    opacity: .3;
    color:#7B2759;
    background:#7B2759;
    pointer-events: all;
    cursor: pointer;
  }

  

