.thumbnail-card {
  margin: 0;
  padding: 0;
}

.whole-wishlist-link {
  &:hover {
    text-decoration: underline dotted #ef6ba0;
  }
}

.wishlist-title-container {
  max-width: 128px;
}

.wishlist-item-name {
  color: #aa809c;
}
