.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 80px;
  background-color: #f7f8f8;
  font-family: "Heebo", sans-serif;
}

body {
  background-color: #fff3f6;
}

.SearchToDonate .search {
  display: block;
  padding-top: 50px;
  padding-bottom: 10px;
  margin: 0 auto;
  font-family: "Heebo", sans-serif;
  border-bottom: 2px solid #c17091;
  caret-color: #c17091;
  color: #590a39;
}

@media (max-width: 767px) {
  .SearchToDonate .search {
    width: 320px;
    font-size: 1.3rem;
    font-weight: 400;
  }
}

@media (min-width: 768px) {
  .SearchToDonate .search {
    width: 400px;
    font-size: 1.7rem;
    font-weight: 200;
  }
}

input {
  border-radius: 0cm;
}

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type="search"]::-moz-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type="search"]:-ms-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #dbacbd;
  width: 100%;
}

input[type="search"]::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7b2759;
  background: #7b2759;
  background-color: #7b2759;
  pointer-events: all;
  cursor: pointer;
}

input[type="search"]:focus::-webkit-search-cancel-button {
  opacity: 0.3;
  color: #7b2759;
  background: #7b2759;
  pointer-events: all;
  cursor: pointer;
}
