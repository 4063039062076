.row.list-container {
  column-gap: 0em;
  row-gap: 2em;
}

.card-container {
  /* centers the cards in the row */
  float: none;
  margin: 0 auto;
  /* other styles */
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  padding-left: 12px;
  padding-right: 12px;

  .card {
    background-color: #f8dde3;
    border: 0px solid grey;
    border-radius: 100px;
    font-family: "Heebo", sans-serif;
    padding: 0px;
    cursor: pointer;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;

    box-shadow: 0 6px 8px 0 rgba(71, 16, 36, 0.06),
      0 6px 20px 0 rgba(71, 16, 36, 0.06);

    p {
      margin-block-start: 0.1em;
      margin-block-end: 0.1em;
    }

    h2 {
      text-align: center;
      font-weight: 500;
      font-size: 0.9rem;
      color: #590a39;
      padding: 4px 10px 0px 10px;
      margin-bottom: 4px;
    }

    .icon-container {
      min-height: 160px;
      display: table;
      margin-top: 6px;

      .icon-container-inner-wrapper {
        vertical-align: middle;
        display: table-cell;
        width: 100%;
      }

      .item-description-container {
        font-size: 0.75rem;
        text-align: center;
        color: #7b2759;
        display: flex;
        justify-content: center;
        align-items: center;

        .item-description {
          display: inline-block;
          background-color: #eecad4;
          border-radius: 10px;
          max-width: 80%;
          line-height: 1;
          padding: 4px 12px;
        }
      }
    }
    .illustrated-icon {
      margin: 0 auto;
      display: block;
      margin: 0 auto;
    }
  }
}

.card-container:hover {
  transform: scale(1.05);
}

.pickup-banner-bar {
  background-color: #e8f9f7;
  display: block;
  text-align: center;
  margin-bottom: 2px;
}

.pickup-text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.9rem;
  color: #44c1b5;
  padding-left: 4px;
  padding-right: 5px;
}

.no-pickup-filler-bar {
  min-height: 34px;
  display: block;
  text-align: center;
}
.pickup-banner-col {
  padding: 0;
}

.needed-by-container {
  background-color: white;
  padding: 0px 0px 2px 0px;
  margin: 0;
  min-height: 140px;

  .needed-by-container-inner-wrapper {
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
  }

  .charity-logo-container {
    padding-right: 2px;
    padding-left: 10px;
    max-width: 120px;
    height: auto;
  }

  .charity-facts-container {
    padding-right: 0px;
    padding-left: 2px;
    display: flex;

    // will need to increase min height at larger breakpoints
    .charity-facts-container-inner-wrapper {
      display: table;
      width: 100%;
      min-height: 70px;
    }

    .charity-facts-text-container {
      vertical-align: middle;
      display: table-cell;
      width: 100%;
      padding-left: 4px;
      padding-right: 8px;
    }

    .needed-by-text {
      font-size: 0.75rem;
      line-height: 0.9rem;
      color: #aa809c;
      font-weight: 500;
    }
    .charity-name {
      font-weight: 700;
      font-size: 0.85rem;
      line-height: 0.9rem;
    }
    .charity-website {
      font-weight: 300;
      font-size: 0.75rem;
    }
  }
}

.donation-details-button-container {
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  padding-bottom: 6px;
  margin: 0;
}
.donation-details-button {
  text-align: center;
  background: rgb(240, 147, 154);
  background: linear-gradient(
    90deg,
    rgba(240, 147, 154, 1) 28%,
    rgba(235, 156, 192, 1) 100%
  );
  border: 0px;
  color: white;
  font-size: 0.85rem;
  line-height: 0.85rem;
  font-weight: 700;
  padding: 10px 14px;
  border-radius: 6px 6px 6px 6px;
  margin: 2px 0px;
  cursor: pointer;
}

.details-page-link {
  &:hover {
    text-decoration: none;

    .charity-name {
      color: #bc2a57;
    }
  }
}
