.header {
  padding: 0;
  margin: 0;
  font-family: "Heebo", sans-serif;

  .sky-background {
    height: 170px;
    background-color: #e8f7f9;
  }

  .heartening-logo-stamp {
    position: absolute;
    top: 0;
    left: 30px;
    background-color: #f8dde3;
    border-radius: 0px 0px 50px 50px;
    padding-top: 10px;
    color: #9b5977;
    font-size: 0.85rem;
    text-align: center;

    .heartening-logo-container {
      margin-right: 6px;
    }

    .heartening-logo {
      margin: 0 auto;
      display: block;
      overflow: hidden;
    }

    .logo-url-text {
      padding-top: 8px;
      color: #f15a2d;
      font-weight: 700;
    }

    svg {
      margin-right: 6px;
    }
  }

  @media (min-width: 768px) {
    .heartening-logo-stamp {
      height: 128px;
      width: 120px;
    }
  }

  @media (max-width: 767px) {
    .heartening-logo-stamp {
      visibility: hidden;
      // height: 108px;
      // width: 70px;
      // left: 2px;
    }
  }

  .book-a-pick-up {
    left: 150px;
    width: 134px;
    height: 42px;
    top: 42px;
    background-color: #f76b39;
    position: absolute;
    border-bottom-right-radius: 26px;
    color: #f8dde3 !important;
    font-size: 0.85em;
    line-height: 3em;
    padding-right: 26px;
    text-align: right;
    font-weight: 700 !important;

    .book-a-pick-up-link {
      color: #f8dde3 !important;
      text-decoration: none !important;
    }

    &:hover {
      background-color: #d8542b;
      text-decoration: none !important;
      cursor: pointer;
    }
  }

  .book-a-drop-off {
    left: 150px;
    width: 190px;
    height: 42px;
    top: 0px;
    background-color: #eda9c8;
    position: absolute;
    border-bottom-right-radius: 26px;
    color: #915071 !important;
    font-size: 0.85em;
    line-height: 3em;
    padding-right: 26px;
    text-align: right;
    font-weight: 700 !important;

    .book-a-drop-off-link {
      color: #915071 !important;
      text-decoration: none !important;
    }

    &:hover {
      background-color: #f499bf;
      text-decoration: none !important;
      cursor: pointer;
    }
  }

  .instagram-blob {
    width: 48px;
    height: 56px;
    background-color: #e0cfe6;
    position: absolute;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    top: 0px;
    right: 28px;
    font-size: 0.8em;
    line-height: 2.7em;
    padding-top: 12px;
    padding-left: 10px;
  }

  .addacause-blob {
    width: 48px;
    height: 56px;
    background-color: #b6e1e3;
    position: absolute;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    top: 0px;
    right: 88px;
    font-size: 0.8em;
    line-height: 2.7em;
    padding-top: 10px;
    padding-left: 9px;
  }

  .about-me-blob {
    width: 60px;
    height: 56px;
    background-color: #f8dde3;
    color: #9b5977;
    font-weight: 600;
    position: absolute;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
    top: 0px;
    right: 148px;
    font-size: 0.8em;
    line-height: 2.7em;
    padding-top: 10px;
    padding-left: 12px;
  }

  .about-me-link {
    text-decoration: none;
    color: #9b5977;
  }

  @media (max-width: 767px) {
    .book-a-pick-up,
    .book-a-drop-off {
      left: 0px;
    }

    .book-a-pick-up {
      width: 130px;
    }

    .book-a-drop-off {
      width: 190px;
    }

    .instagram-blob {
      right: 12px;
    }

    .addacause-blob {
      right: 72px;
    }

    .about-me-blob {
      right: 132px;
    }

    .thick-ribbon {
      height: 4.5rem;
    }
  }

  @media (min-width: 768px) {
    .thick-ribbon {
      height: 5.5rem;
    }
  }

  .thick-ribbon {
    background-color: #f8dde3;

    .header-center-anchor {
      width: 1px; /* Or zero, or something very small */
      margin: auto;
      overflow: visible;
    }

    @media (max-width: 767px) {
      .nav-container {
        margin-left: 200px;
        margin-top: -90px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 905px) {
      .nav-container {
        margin-left: 146px;
        margin-top: -90px;
      }
    }
    @media (min-width: 906px) {
      .nav-container {
        margin-left: 200px;
        margin-top: -90px;
      }
    }

    .city-statement {
      // text-align: left;
      font-size: 0.9rem;
      line-height: 5.5rem;
      // padding-left: 30px;
      color: #9b5977;
      width: 270px;

      .selected-city {
        font-weight: 800;
        margin-left: 6px;
      }
    }
    @media (max-width: 767px) {
      .city-statement {
        margin-left: -400px;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 905px) {
      .city-statement {
        margin-left: -340px;
      }
    }
    @media (min-width: 906px) {
      .city-statement {
        margin-left: -430px;
      }
    }
  }

  .nav-container {
    font-size: 0.9rem;
    line-height: 1rem;
    // padding-left: 30px;
    color: #9b5977;
    width: 225px;
    margin: 0 auto;

    .pages-nav-pills {
      background: #fbebee;
      padding: 6px;
      border-radius: 8px;

      .nav-item {
        // padding: 6px;

        .page-nav-icon {
          margin-right: 6px;
        }

        .nav-link {
          padding: 0.7rem 1rem;
        }

        a {
          color: #9b5977;
          font-weight: 700;
        }
      }

      .nav-link.active {
        background-color: white;
      }
    }
  }

  .mobile-city-statement {
    text-align: center;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 4.8rem;
    color: #9b5977;
    width: 100%;

    .selected-city {
      font-weight: 800;
      margin-left: 6px;
    }
  }

  .city-illustration {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 767px) {
    .city-illustration {
      width: 336px;
      height: 224px;
      top: 46px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 905px) {
    .city-illustration {
      width: 356px;
      height: 224px;
      top: 44px;
    }
  }
  @media (min-width: 906px) {
    .city-illustration {
      width: 446px;
      height: 224px;
      top: 44px;
    }
  }

  .logo-container {
    width: 100px;
    height: 100px;
    padding: 25px;
  }

  .options {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .option {
      padding: 8px 15px;
      cursor: pointer;
    }
  }
}
