.search {
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 10px;
    width: 380px;
    line-height: 30px;
    margin-bottom: 30px;
    background-color: #FFF3F6;
    color: #590a39;
}