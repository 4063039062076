@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;700&display=swap');

body {
  font-family: 'Heebo';
  padding: 0;
}

a {
  text-decoration: none;
  color:black;
}

* {
  box-sizing: border-box;
}