.about-text-container {
  color: #9b5977;
  font-size: 1em;
  margin-top: 30px;
}

h1.page-title {
  text-align: center;
  font-weight: 200;
  font-size: 1.7rem;
  color: #9b5977;
  font-family: "Heebo";
  padding: 32px 0 20px 0;
}
h2 {
  font-weight: 600;
  font-size: 1.1rem;
}

.about-me-circle-imgs {
  width: 100%;
  max-width: 160px;
  vertical-align: middle;
}

.link-to-outside-pages {
  font-weight: 600;
  text-decoration: none;
  color: #9b5977;
  & :hover {
    text-decoration: none;
    color: #9b5977;
  }
}
