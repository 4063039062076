.page-container {
  margin-top: 40px;
}

.whole-list-area {
  margin-left: 40px;
  margin-right: 40px;
}

.draggable-overall-container {
  position: relative;
  width: 100%;
  height: 240px;
  display: inline-block;
}

@media (max-width: 767px) {
  .draggable-overall-container {
    width: 420px;
  }
}

@media (min-width: 768px) {
  .draggable-overall-container {
    width: 100%;
  }
}

.admin-wishlist-page .draggable-item-area {
  position: absolute;
  width: 100%;
  height: 80px;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: #590a39;
  line-height: 80px;
  padding-left: 32px;
  font-size: 0.9rem;
  font-weight: 500;
  background: white;
  text-transform: lowercase;
  touch-action: none;
}

.ranking-col {
  max-width: 15%;
}

.ranking-container {
  padding-top: 28px;
  display: inline-block;
  width: 20px;
}

.rank-label {
  height: 100px;
  text-align: right;
}

.about-me-circle-imgs {
  width: 100%;
  max-width: 160px;
  vertical-align: middle;
}
