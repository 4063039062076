@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@200;400;500;700&display=swap");

.list-container {
  column-gap: 0em;
  row-gap: 2em;
}

.hidden {
  display: none;
}

h1 {
  text-align: center;
  font-weight: 200;
  font-size: 1.7rem;
  color: #9b5977;
  font-family: "Heebo";
  padding: 18px 0 0 0;
}

.row.list-container {
  grid-row-gap: 1em;
  row-gap: 1em;
}

.charity-card-container {
  /* centers the cards in the row */
  float: none;
  margin: 0 auto;
  /* other styles */
  transform: none;
  transition: none;
  font-family: "Heebo", sans-serif;

  @media (max-width: 767px) {
    .charity-card {
      margin-left: 10px;
      margin-right: 10px;
      cursor: default;

      .charity-text-area {
        padding-left: 20px;
        padding-bottom: 12px;
        text-align: center;
      }
      .charity-text-area h2 {
        text-align: center;
      }
      img.charity-logo {
        margin: 0 auto;
        text-align: center;
        display: block;
        width: 140px;
      }

      .org-type-container {
        margin: 0 auto;
        text-align: center;
        display: block;
      }

      .mission-statement {
        margin-left: 5%;
        margin-right: 5%;
      }
      .charity-text-area h2 {
        padding: 6px 2px 0px 0px;
      }
    }
  }

  @media (min-width: 768px) {
    .charity-text-area {
      padding-left: 2px;
    }
    img.charity-logo {
      margin: 0 auto;
      text-align: center;
      display: block;
    }
    .mission-statement {
      width: 90%;
    }
    .charity-text-area h2 {
      padding: 18px 2px 0px 0px;
    }
  }

  .charity-card {
    background-color: white;
    border: 0px solid grey;
    border-radius: 10px;
    font-family: "Heebo", sans-serif;
    padding: 0px;
    cursor: default;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    min-height: 20px;
    box-shadow: 0 4px 8px 0 rgba(71, 16, 36, 0.06),
      0 6px 20px 0 rgba(71, 16, 36, 0.06);

    p {
      margin-block-start: 0.1em;
      margin-block-end: 0.1em;
    }

    .charity-website {
      padding-top: 0px;
      padding-bottom: 4px;
      font-size: 0.9rem;

      a {
        color: #875378;
      }
    }

    .mission-statement {
      padding-bottom: 4px;
      font-size: 0.9rem;
      line-height: 20px;
      font-family: "Heebo";
      color: #350b16;
    }
    h1.page-title {
      text-align: left;
      font-weight: 200;
      font-size: 1.2rem;
      color: #9b5977;
      font-family: "Heebo";
    }
    h2 {
      text-align: left;
      font-weight: 500;
      font-size: 1.2rem;
      color: #590a39;
    }

    h3 {
      text-align: left;
      font-weight: 500;
      font-size: 1.15rem;
      color: #590a39;
      padding: 6px 2px 0px 0px;
    }

    h4 {
      text-align: left;
      font-weight: 500;
      font-size: 1.2rem;
      color: #590a39;
      padding: 6px 2px 6px 0px;
    }

    h4.wishlist-title {
      color: #986c4b;
      font-size: 0.95rem;
    }

    .cols-for-wishlist-tiles {
      padding-left: 8px;
      padding-right: 8px;
    }

    .wishlist-item-name {
      line-height: 1rem;
      color: #590a39;
      font-weight: 400;
      font-size: 0.8rem;
      text-align: left;
      padding-bottom: 8px;
    }

    .wishlist-items-container {
      background: white;
      border-radius: 20px;
      border: 1px solid #efd6c5;
    }

    .ui-icon {
      margin-right: 6px;
    }

    .wishlist-icon {
      margin-right: 8px;
    }

    .charity-summary-container {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .charity-category-badge {
      background-color: white;
      padding: 4px 10px;
      font-size: 86%;
      color: #aa809c;
      border: 1px solid #aa809c;
      margin-bottom: 10px;
      text-transform: lowercase;
    }

    .org-type-badge {
      padding: 5px 10px;
      font-size: 82%;
      z-index: 500;
      position: relative;
    }

    .org-type-badge-and-icon {
      display: block;
      margin: 0 auto;
      text-align: center;
    }

    .org-type-badge.org-type-government {
      background-color: #87522e;
    }

    .org-type-badge.org-type-business {
      background-color: #0c7265;
    }

    .org-type-badge.org-type-nonprofit {
      background-color: #911f3d;
    }

    .org-type-badge.org-type-community-effort {
      background-color: #738e2f;
    }

    .org-type-icon {
      margin-right: -8px;
      z-index: 5000 !important;
      position: relative;
    }

    .wishlist-button {
      background-color: #fcecde;
      color: #986c4b;
      border-color: #efd6c5;
      border-radius: 30px;
      padding-top: 15px;
      line-height: 36px;
      padding-left: 12px;
      padding-right: 20px;
      margin: 12px;
      text-align: center;
      display: inline-flex;

      &:hover {
        background-color: #fdb73e;
        text-decoration: none;
      }
    }

    .wishlist-link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }

    .items-accordion-container {
      padding: 0px;
      color: #590a39;

      .accordion-trigger-link {
        text-decoration: none;
        color: #590a39;

        &:hover {
          text-decoration: none;
          color: #590a39;
        }
      }

      .cols-for-tiles {
        padding: 0px;
        margin: 0px;
      }

      .accordion {
        border-radius: 0rem 0rem 0.5rem 0.5rem;
      }

      .donated-thing-icon {
        padding-bottom: 3px;
        top: -3px;
        margin-right: 6px;
      }

      .want-count-text {
        margin-right: 16px;
      }

      .card {
        background-color: #f8dde3;
        border-radius: 0rem 0rem 0.5rem 0.5rem;

        .wishlist-heading {
          margin-top: 8px;
        }

        .external-website-link-container {
          margin-bottom: 30px;
        }

        .thumbnail-card {
          margin: 10px;
        }

        .item-name {
          line-height: 1rem;
          color: #590a39;
          font-weight: 500;
          font-size: 0.9rem;
          text-align: center;
          text-transform: lowercase;
          padding-top: 4px;
        }

        .item-description-container {
          font-size: 0.75rem;
          text-align: center;
          color: #7b2759;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 0.7rem;
          margin: 0;
          padding: 0;

          .item-description {
            display: inline-block;
            background-color: #eecad4;
            border-radius: 10px;
            max-width: 100%;
            line-height: 1;
            padding: 5px 8px;
          }
        }
      }
    }

    .illustrated-icon {
      margin: 0 auto;
      display: block;
      padding: 2px;
    }
  }
}

.charity-card-container:hover {
  transform: none;
  cursor: default;
}

.arrange-pickup-container {
  text-align: center;
  line-height: 1.2rem;

  .arrange-pickup-title {
    color: #590a39;
    font-size: 0.95rem;
    font-weight: 700;
    padding-top: 8px;
  }
  .arrange-pickup-description {
    color: #8b3f6b;
    font-size: 0.9rem;
  }
  .arrange-pickup-cta {
    color: #8b3f6b;
    font-size: 0.9rem;
    font-weight: 700;
  }
  .arrange-pickup-zip-codes {
    color: #aa809c;
    font-size: 0.8rem;
    padding-top: 6px;
  }
}

.donation-detail-label {
  color: #aa809c;
  font-size: 0.85rem;
  padding-top: 12px;
}
.copy-text-button,
.small-button {
  color: #ef6ca0;
  font-size: 0.85rem;
  background-color: none;
  border: none;
  margin-top: 4px;
  margin-bottom: 8px;

  &:hover {
    background: #fff3f6;
    color: #ef6ca0;
    text-decoration: none;
    border: none;
    cursor: pointer;
  }

  &:active {
    background: #fff3f6 !important;
    color: #ef6ca0 !important;
    text-decoration: none;
    border: none;
  }
  &:focus {
    background: #fff3f6;
    color: #ef6ca0;
    text-decoration: none;
    border: none;
  }
  &:visited {
    background: #fff3f6;
    color: #ef6ca0;
    text-decoration: none;
    border: none;
  }
}

.donation-email {
  color: #aa437e;
}
.donation-instructions {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: "Heebo";
  color: #8b3f6b;
  text-align: center;
  padding: 12px;
}

.donation-address,
.donation-coordination-name,
.donation-email {
  font-size: 0.9rem;
  font-weight: 700;
}

.donation-details-button.charity-type {
  background: rgb(240, 147, 154);
  background: linear-gradient(
    90deg,
    rgba(240, 147, 154, 1) 28%,
    rgba(235, 156, 192, 1) 100%
  );
  color: white;
  border-color: #efd6c5;
  border-radius: 32px;
  padding-top: 20px;
  line-height: 36px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 6px;
  text-align: center;
  display: inline-flex;

  &:hover {
    background: rgba(235, 156, 192, 1);
    text-decoration: none;
    cursor: pointer;
  }

  .details-title {
    font-size: 0.95rem;
  }
}

.popover {
  border: 1px solid #ee9ab1;
  z-index: 2500;
}

.maps-link {
  font-size: 0.85rem;
  color: #ef6ca0;
  background-color: #f8f9fa;
  border: none;
  border-top: 3px solid #f8f9fa;
  padding: 2px 8px 3px 8px;
  border-radius: 0.2rem;
  margin: 0px 8px 10px 0px;

  &:hover {
    background: #fff3f6;
    color: #ef6ca0;
    text-decoration: none;
    border-top: 3px solid #fff3f6;
    cursor: pointer;
  }
}

.top-popover .popover-body {
  color: #d15688;
  padding: 0.3rem 0.75rem;
}

.popover .arrow {
  border-width: 1px 1px 0;
  border-top-color: #ee9ab1 !important;
}

.bs-popover-auto[x-placement^="top"] > .arrow::before,
.bs-popover-top > .arrow::before {
  border-top-color: #ee9ab1 !important;
}

.details-link {
  &:hover {
    text-decoration: none;
  }
}

.wishlist-guidance {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: "Heebo";
  color: #875378;
  text-align: center;
  padding: 2px;
}
